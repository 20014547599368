import React from 'react';
import parse from 'html-react-parser';
import * as styles from './Timeline.module.scss';
import clsx from 'clsx';
import TimelineItem from './TimelineItem';

const Timeline = ({ title, timeline }) => {

  return (
    <section className={clsx(styles.timeline)}>
      <div className={clsx('section-wrapper', styles.wrapper)}>
        {title && <div className={styles.title}>{parse(title)}</div>}
        <div className={styles.tiles}>
          {timeline.map((tile, i) => 
            <TimelineItem key={i} { ...tile }/>
          )}
        </div>
      </div>
    </section>
  )
};

export default Timeline;