import React from 'react';
import parse from 'html-react-parser';
import * as styles from './TimelineItem.module.scss';
import clsx from 'clsx';

const TimelineItem = ({ headline, paragraph, year, is_active }) => {

  return (
    <div className={clsx(styles.timelineItem, is_active && 'timeline-active')}>
      <div className={styles.date}>
        <div className={styles.dateTracker}></div>
        <div className={styles.year}>{parse(year.text)}</div>
      </div>
      <div className={styles.box}>
        { headline && <div className={styles.title}><p>{parse(headline.html)}</p></div>}
        { paragraph && <div className={styles.text}><p>{parse(paragraph.html)}</p></div>}
      </div>
    </div>
  )
};

export default TimelineItem;